body {
	margin: 0;
	font-family: Lato, ui-sans-serif, system-ui, -apple-system,
		BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif,
		"Segoe UI", Tahoma, Geneva, Verdana, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Lato,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" */
