body {
	background-color: #111d13;
}

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #709775;
}

textarea#output-macro {
	resize: none;
	height: 200px;
	background-color: #a1cca5;
}

.chatsList {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 180px;
	width: 400px;
}

.App {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.App > div {
	width: 45%;
	padding: 5% 2.5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px dashed #a1cca5;
	border-radius: 2%;
	margin: 1% 0.25%;
}

h1 {
	margin: 1.5% 0;
}

h2 {
	margin: 0.5% 0;
	font-size: 12px;
	text-transform: uppercase;
}

.copy {
	font-size: 12px;
	margin: 2% 5%;
}

.selects {
	width: 100%;

	display: flex;
	justify-content: center;
}

select {
	padding: 1.25% 1.75%;
	border-radius: 5px 5px;
	background-color: #a1cca5;
	border: 1px solid white;
	margin: 1% 0.5%;
}

@media screen and (max-width: 1080px) {
	.App {
		width: 100%;
		flex-direction: column;
		margin: 3rem 0;
	}

	.App > div {
		width: 80%;
	}
}

@media screen and (min-width: 1081px) {
	.App {
		width: 1080px;
		margin: 5% auto;
		display: flex;
		align-items: stretch;
	}

	.App > div {
		justify-content: center;
	}
}

textarea {
	white-space: pre-wrap;
	padding: 12px 20px;
	resize: none;
	background-color: #a1cca5;
	border: 2px solid white;
	border-radius: 2.5%;
}

button {
	margin-top: 2%;
	padding: 1% 2.5%;
	background-color: white;
	border-radius: 8%;
	text-transform: uppercase;
	font-weight: bold;
}

button:focus,
button:hover {
	background-color: #709775;
	color: white;
	transition: all 0.08s ease-in;
}

a {
	color: inherit;
	color: white;
	font-weight: bold;
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: underline;
}
